import { ColorLabel } from '@capturi/charts'
import { Segment } from '@capturi/filters'
import { Box, Flex } from '@chakra-ui/react'
import { formatValue, pctFormat } from 'pages/analytics/shared/utils'
import React from 'react'

import Tab from '../../shared/components/Tab'

type Props = {
  label: React.ReactNode
  values: Segment<number>[]
  isLoading?: boolean
  onClick?: () => void
}

const SentimentTab: React.FC<Props> = ({
  label,
  values = [],
  isLoading = false,
  ...restProps
}) => {
  return (
    <Tab.Container {...restProps}>
      <Tab.Title label={label} />
      <Tab.Display>
        {() => {
          return values.length === 1 ? (
            <>
              <Flex align="start" justify="space-between">
                <Tab.Value isLoading={isLoading}>
                  {formatValue(values[0].data || 0, pctFormat)}
                </Tab.Value>
              </Flex>
              <Tab.CustomProgress
                isLoading={isLoading}
                min={0}
                max={1}
                value={values[0].data || 0}
              />
            </>
          ) : (
            <Box
              display="inline-flex"
              flexDirection="column"
              fontSize="0.875rem"
              lineHeight={1.2}
              fontWeight="500"
              color="gray.800"
            >
              {values.map((v) => (
                <Flex
                  display="inline-flex"
                  justify="space-between"
                  align="center"
                  wrap="nowrap"
                  key={v.label}
                >
                  <ColorLabel color={v.color} m={1} mr={2} />
                  <Tab.Value isLoading={isLoading}>
                    {formatValue(v.data || 0, pctFormat)}
                  </Tab.Value>
                </Flex>
              ))}
            </Box>
          )
        }}
      </Tab.Display>
    </Tab.Container>
  )
}

export default SentimentTab
