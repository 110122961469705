import {
  SentimentScore,
  SentimentScoreTeamHitsResponseModel,
  sentimentAPI,
} from '@capturi/api-sentiment'
import {
  toFilterSearchParams,
  useFetchSegments,
  useFilterPeriodContext,
  useSegmentStatesContext,
} from '@capturi/filters'
import { useUsers } from '@capturi/stores'
import { PlaySnippetsButton } from '@capturi/ui-components'
import { i18n } from '@lingui/core'
import { t } from '@lingui/macro'
import { useStore } from '@tanstack/react-store'
import { useConversationsDrawer } from 'components/ConversationsDrawer'
import { DataGrid, DataGridColumn } from 'components/DataGrid'
import React from 'react'
import NoDataText from '../../shared/components/NoDataText'
import UserBreakdownSkeleton from '../../shared/components/UserBreakdownSkeleton'
import SpeakerContext from '../contexts/SpeakerContext'
import { plottableTeamsState } from '../data/state'
import useTeamScoreStats, { TeamData } from '../data/useTeamScoreStats'
import { Event, logEvent } from '../events'

const hasHits = (d: TeamData): boolean =>
  d.segments.some((x) => x.data?.hits && x.data.hits > 0)

const formatHitRate = (value?: number): string => {
  if (value === undefined) return ''
  return i18n.number(value, {
    style: 'percent',
    minimumFractionDigits: 0,
    maximumFractionDigits: value < 0.01 ? 1 : 0,
  })
}

const UserHits: React.FC<{
  sentimentScore: SentimentScore
}> = ({ sentimentScore }) => {
  const speaker = React.useContext(SpeakerContext)
  const { phoneSegmentStates: states } = useSegmentStatesContext()
  const openConversationsDrawer = useConversationsDrawer()
  const { periodDef } = useFilterPeriodContext()

  const selectedTeams = useStore(plottableTeamsState)

  const { segments, isLoading } =
    useFetchSegments<SentimentScoreTeamHitsResponseModel>(() =>
      sentimentAPI.getSentimentScoreTeamHits(speaker, sentimentScore),
    )

  const { getUsersByTeamUid } = useUsers()
  const rowData = useTeamScoreStats(segments)
  const segmentsCount = states.length

  const handleViewConversations = React.useCallback(
    (valueIndex: number, teamUid: string): void => {
      logEvent(
        Event.Team_ViewSentimentScoreConversations,
        speaker,
        sentimentScore,
      )
      const state = states[valueIndex].values
      const userUids = getUsersByTeamUid(teamUid).map((u) => u.uid)
      openConversationsDrawer({
        url: 'conversations/list?api-version=3.3&excludeDeleted=false',
        getFilterRequestModel: () =>
          toFilterSearchParams(
            {
              ...state,
              userUids: userUids,
              sentiment: {
                score: sentimentScore,
                speaker,
              },
            },
            periodDef,
          ),
        initialTab: 'sentiment',
      })
    },
    [
      speaker,
      sentimentScore,
      states,
      getUsersByTeamUid,
      openConversationsDrawer,
      periodDef,
    ],
  )

  const handleSelectRows = React.useCallback(
    (rows: TeamData[]) => {
      const teams = rows.map((x) => x.team.uid)
      plottableTeamsState.setState(() => teams)
      logEvent(Event.Team_PlotTimeSeries, speaker, sentimentScore, {
        userPlotCount: teams.length,
        segmentCount: segmentsCount,
      })
    },
    [speaker, sentimentScore, segmentsCount],
  )

  const columns = React.useMemo(() => {
    const columns: Array<DataGridColumn<TeamData>> = [
      {
        type: 'team',
        getValue: (d) => d.team.uid,
        width: 120,
      },
      {
        type: 'value',
        id: 'hit-rate,',
        getSegmentValue: (s) => s.data?.hitRate,
        formatValue: formatHitRate,
        alignRight: true,
      },
      {
        type: 'progress',
        getSegmentValue: (s) => s.data?.hitRate,
        formatValue: formatHitRate,
        range: [0, 1],
      },
      {
        type: 'dataBasis',
        getSegmentValue: (s) => {
          const { hits = 0, conversations = 0 } = s.data ?? {}
          return {
            value: hits,
            total: conversations,
          }
        },
      },
      {
        type: 'button',

        render: (row, segmentIndex) => (
          <PlaySnippetsButton
            label={t`View conversations`}
            onClick={() => handleViewConversations(segmentIndex, row.team.uid)}
          />
        ),
      },
    ]
    return columns
  }, [handleViewConversations])

  const initialSelectedRowsRef = React.useRef(() => {
    return rowData.reduce<Record<string, boolean>>((memo, row, i) => {
      if (selectedTeams.indexOf(row.team.uid) !== -1) {
        memo[i] = true
      }
      return memo
    }, {})
  })

  return (
    <UserBreakdownSkeleton isLoaded={!isLoading}>
      <DataGrid
        data={rowData}
        columns={columns}
        selectableRows
        initialSelectedRows={initialSelectedRowsRef.current}
        onSelectedRowsChange={handleSelectRows}
        isRowSelectable={hasHits}
      >
        <NoDataText show={!isLoading && rowData.length === 0} />
      </DataGrid>
    </UserBreakdownSkeleton>
  )
}

export default UserHits
