import {
  SentimentScore,
  SentimentScoreTimeSeriesEntry,
  SentimentScoreTimeSeriesResponseModel,
  sentimentAPI,
} from '@capturi/api-sentiment'
import {
  CartesianGrid,
  DashedLineVariants,
  DateBucket,
  Line,
  LineChartSkeleton,
  LineDescriptor,
  PrimaryLine,
  ReferenceLine,
  TimeSeriesChart,
  TimeSeriesTooltip,
  XAxis,
  YAxis,
  adjustDateForDSTOverlap,
} from '@capturi/charts'
import { TimeResolution, useTeams } from '@capturi/core'
import {
  Segment,
  useFetchMultiSegments,
  useFetchSegments,
  useFilterPeriodContext,
} from '@capturi/filters'
import { useUsers } from '@capturi/stores'
import { Box, HStack } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { isWeekend } from 'date-fns'
import uniq from 'lodash/uniq'
import React from 'react'
import { ResponsiveContainer, Tooltip } from 'recharts'

import { useStore } from '@tanstack/react-store'
import ResolutionButtons from '../../shared/components/ResolutionButtons'
import { useTimeResolutionContext } from '../../shared/contexts/ResolutionContext'
import useOnlyWeekdaysToggle from '../../shared/hooks/useOnlyWeekdaysToggle'
import { pctFormat } from '../../shared/utils'
import SpeakerContext from '../contexts/SpeakerContext'
import {
  plottableTeamsState,
  plottableUsersState,
  sentimentTimeDataTabIndex,
} from '../data/state'
import { SentimentScoreHits } from './DataTabs'

type Props = {
  sentimentScore: SentimentScore
  segmentAverages: Segment<SentimentScoreHits>[]
}

const TimeDataContainer: React.FC<Props> = ({
  sentimentScore,
  segmentAverages,
}) => {
  const { i18n } = useLingui()
  const speaker = React.useContext(SpeakerContext)
  const { resolution, setResolution } = useTimeResolutionContext()
  const { period } = useFilterPeriodContext()
  const tzOffset = period.from.getTimezoneOffset() * -1
  const { getUserByUid } = useUsers()
  const { getTeamByUid } = useTeams()

  const tabIndex = useStore(sentimentTimeDataTabIndex)
  const secondaryChartType: 'users' | 'teams' = (() => {
    if (tabIndex === 0) {
      return 'users'
    }
    return 'teams'
  })()

  const [plotOnlyWeekdays, OnlyWeekdaysToggle] = useOnlyWeekdaysToggle()
  const plottableUsers = useStore(plottableUsersState)
  const plottableTeams = useStore(plottableTeamsState)

  const onResolutionChange = (resolution: TimeResolution): void => {
    setResolution(resolution)
  }

  const { segments, isLoading } =
    useFetchSegments<SentimentScoreTimeSeriesResponseModel>(() =>
      sentimentAPI.getSentimentScoreTimeSeries(
        speaker,
        sentimentScore,
        resolution,
        tzOffset,
      ),
    )

  // Fetch segment data for all selected users
  const userTimeSeriesData =
    useFetchMultiSegments<SentimentScoreTimeSeriesResponseModel>(
      plottableUsers,
      () => {
        return sentimentAPI.getSentimentScoreTimeSeries(
          speaker,
          sentimentScore,
          resolution,
          tzOffset,
        )
      },
      (state, userUid, periodDef, toFilterSearchParams) => {
        return toFilterSearchParams(
          {
            ...state.values,
            userUids: uniq((state.values.userUids ?? []).concat(userUid)),
          },
          periodDef,
        )
      },
      {
        // Do not fetch data unless on users tab
        enabled: secondaryChartType === 'users',
      },
    )

  // Fetch segment data for all selected teams
  const teamTimeSeriesData =
    useFetchMultiSegments<SentimentScoreTimeSeriesResponseModel>(
      plottableTeams,
      () =>
        sentimentAPI.getSentimentScoreTimeSeries(
          speaker,
          sentimentScore,
          resolution,
          tzOffset,
        ),
      (state, teamUid, periodDef, toFilterSearchParams) => {
        return toFilterSearchParams(
          {
            ...state.values,
            teamUids: uniq((state.values.teamUids ?? []).concat(teamUid)),
          },
          periodDef,
        )
      },
      {
        // Do not fetch data unless on teams tab
        enabled: secondaryChartType === 'teams',
      },
    )

  const results = React.useMemo(() => {
    const buckets: Record<string, DateBucket> = {}

    const getOrCreateBucket = (date: Date): DateBucket => {
      const bucketKey = date.toISOString()
      if (buckets[bucketKey] === undefined) {
        buckets[bucketKey] = {
          date,
          words: {},
          users: {},
          teams: {},
        }
      }
      return buckets[bucketKey]
    }

    function addSegmentDataToBucket(
      segments: Segment<{ series: SentimentScoreTimeSeriesEntry[] } | null>[],
      nestedProperty?: string,
    ): void {
      segments.forEach((s) => {
        ;(s.data?.series ?? []).forEach((x) => {
          const date = adjustDateForDSTOverlap(x.dateTime, tzOffset)

          // Omit weekend data?
          if (
            plotOnlyWeekdays &&
            isWeekend(date) &&
            (resolution === 'Hours' || resolution === 'Days')
          ) {
            return
          }

          const segmentKey = s.label
          const segmentColor = s.color
          const { dateTime, conversationsWithScorePercent, ...restData } = x
          const bucket = getOrCreateBucket(date)
          const value =
            conversationsWithScorePercent == null
              ? null
              : conversationsWithScorePercent / 100

          const datum = {
            value,
            segmentColor,
            ...restData,
          }

          let root = bucket
          if (nestedProperty !== undefined) {
            root = bucket[nestedProperty] ?? {}
            root[segmentKey] = root[segmentKey] ?? []
          }

          if (Array.isArray(root[segmentKey])) {
            root[segmentKey] = [...root[segmentKey], datum]
          } else {
            root[segmentKey] = datum
          }
        })
      })
    }

    // Add primary segment data
    addSegmentDataToBucket(segments)

    // Add user data
    userTimeSeriesData.forEach((userData) => {
      addSegmentDataToBucket(userData.segments, 'users')
    })

    // Add team data
    teamTimeSeriesData.forEach((teamData) => {
      addSegmentDataToBucket(teamData.segments, 'teams')
    })

    const segmentLines = segments.reduce<LineDescriptor[]>((memo, s) => {
      // Only add lines when we also have data. Charts will do weird aninmations otherwise.
      if (s.data == null) return memo
      memo.push({
        id: `primary:${s.label}:${s.color}`,
        color: s.color,
        label: s.label,
        getValue: (d) => {
          return d[s.label]?.value ?? null
        },
      })
      return memo
    }, [])

    const userLines = userTimeSeriesData.flatMap<LineDescriptor>(
      (userData, i) => {
        const variant = DashedLineVariants[i % DashedLineVariants.length]
        return userData.segments.map((s) => {
          const userUid = userData.source
          return {
            id: `secondary:${userUid}:${s.label}:${s.color}`,
            color: s.color,
            label: getUserByUid(userUid).name,
            getValue: (d) => {
              return d.users[s.label]?.[i]?.value ?? null
            },
            variant,
          }
        })
      },
    )

    const teamLines = teamTimeSeriesData.flatMap<LineDescriptor>(
      (teamData, i) => {
        const variant = DashedLineVariants[i % DashedLineVariants.length]
        return teamData.segments.map((s) => {
          const teamUid = teamData.source
          return {
            id: `secondary:${teamUid}:${s.label}:${s.color}`,
            color: s.color,
            label: getTeamByUid(teamUid)?.name ?? teamUid,
            getValue: (d) => {
              return d.teams[s.label]?.[i]?.value ?? null
            },
            variant,
          }
        })
      },
    )

    return {
      dataset: Object.values(buckets),
      segmentLines,
      userLines,
      teamLines,
    }
  }, [
    segments,
    userTimeSeriesData,
    teamTimeSeriesData,
    tzOffset,
    plotOnlyWeekdays,
    resolution,
    getUserByUid,
    getTeamByUid,
  ])

  const referenceLines = segmentAverages.map(({ color, label, data }) => ({
    label,
    color,
    y: data?.hitRate ?? undefined,
  }))
  let secondaryLines: LineDescriptor[]
  if (secondaryChartType === 'users') {
    secondaryLines = results.userLines
  } else {
    secondaryLines = results.teamLines
  }
  return (
    <Box>
      <HStack spacing={4}>
        <ResolutionButtons
          resolution={resolution}
          setResolution={onResolutionChange}
        />
        {OnlyWeekdaysToggle}
      </HStack>
      <Box w="100%" h="18rem" mt={4}>
        <LineChartSkeleton isLoaded={!isLoading}>
          <ResponsiveContainer width="100%" height="100%" minWidth={100}>
            <TimeSeriesChart
              data={results.dataset}
              resolution={resolution}
              margin={{
                right: 50,
              }}
            >
              <CartesianGrid />
              <Tooltip
                content={
                  <TimeSeriesTooltip
                    secondaryType={secondaryChartType}
                    resolution={resolution}
                    formatValue={(value, name, d) => {
                      const hits = d[name].conversationsWithScore
                      const total = d[name].conversations
                      const numberValue = i18n.number(value, pctFormat)
                      return t`${numberValue} (${hits} out of ${total} conversations)`
                    }}
                  />
                }
              />
              <XAxis dataKey="date" />
              <YAxis valueType="percent" />
              {results.segmentLines.map((line) => (
                <PrimaryLine key={line.id} line={line} />
              ))}
              {secondaryLines.map((line) => (
                <Line key={line.id} line={line} />
              ))}
              {referenceLines.map((line) => (
                <ReferenceLine key={line.label} line={line} />
              ))}
            </TimeSeriesChart>
          </ResponsiveContainer>
        </LineChartSkeleton>
      </Box>
    </Box>
  )
}

export default TimeDataContainer
