import { SentimentScoreUserHitsResponseModel } from '@capturi/api-sentiment'
import { Segment } from '@capturi/filters'
import { useUsers } from '@capturi/stores'
import orderBy from 'lodash/orderBy'
import { useMemo } from 'react'

import { UserSegments } from '../../shared/types'

export type Datum = {
  conversations: number
  hits: number
  hitRate: number
}

export type UserData = UserSegments<Datum>

function useUserScoreStats(
  segments: Segment<SentimentScoreUserHitsResponseModel>[],
): UserData[] {
  const { getUserByUid } = useUsers()

  return useMemo(() => {
    const allUserUids = new Set(
      segments.flatMap((s) => (s.data?.users ?? []).map((x) => x.userUid)),
    )

    const hitMaps = segments.map<{ [key: string]: Datum }>((s) => {
      return (s.data?.users ?? []).reduce<{
        [key: string]: Datum
      }>((acc, x) => {
        acc[x.userUid] = {
          conversations: x.conversations,
          hits: x.conversationsWithScore,
          hitRate: x.conversationsWithScorePercent / 100,
        }
        return acc
      }, {})
    })

    const segmentData = [...allUserUids].reduce<{ [key: string]: UserData }>(
      (acc, userUid) => {
        acc[userUid] = {
          segments: segments.map<Segment<Datum>>((s, i) => {
            const map = hitMaps[i]
            return {
              color: s.color,
              label: s.label,
              data: map[userUid] ?? {
                conversations: 0,
                hits: 0,
                hitRate: 0,
              },
            }
          }),
          user: getUserByUid(userUid),
        }
        return acc
      },
      {},
    )

    const data = orderBy(
      Object.values(segmentData),
      [(x) => x.segments[0]?.data?.hitRate, (x) => x.user.name],
      ['desc', 'asc'],
    )

    return data
  }, [segments, getUserByUid])
}

export default useUserScoreStats
