import { TimeResolution } from '@capturi/core'
import { RequestOptions } from '@capturi/request'

import { SentimentSpeaker } from './models'

const DEFAULT_API_VERSION = '3.3'

const controller = 'sentiments'
const baseQuery = {
  'api-version': DEFAULT_API_VERSION,
}

export const sentimentAPI = {
  getSpeakerSentiment: (speaker: SentimentSpeaker): RequestOptions => ({
    url: `${controller}/${speaker}`,
    query: baseQuery,
    method: 'post',
  }),
  getSentimentScoreUserHits: (
    speaker: SentimentSpeaker,
    score: string,
  ): RequestOptions => ({
    url: `${controller}/${speaker}/${score}/users`,
    query: baseQuery,
    method: 'post',
  }),
  getSentimentScoreTeamHits: (
    speaker: SentimentSpeaker,
    score: string,
  ): RequestOptions => ({
    url: `${controller}/${speaker}/${score}/teams`,
    query: baseQuery,
    method: 'post',
  }),
  getSentimentScoreTimeSeries: (
    speaker: SentimentSpeaker,
    score: string,
    resolution: TimeResolution,
    offset: number,
  ): RequestOptions => ({
    url: `${controller}/${speaker}/${score}/time-series`,
    query: {
      ...baseQuery,
      resolution,
      offset,
    },
    method: 'post',
  }),
}
